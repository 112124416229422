import config from 'config'

export function getGuaClientId () {
  let gua_client_id = document.cookie.match(new RegExp(config.gua.session_id_cookie + '=GA[0-9]+.[0-9]+.([a-z0-9]+).([a-z0-9]+)', 'i'))
  if (gua_client_id !== null) {
    return gua_client_id[1] + '.' + gua_client_id[2]
  } else {
    return null
  }
}
