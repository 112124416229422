import rootStore from '@vue-storefront/core/store'

function storeOrderSummary (responseData) {
  if (responseData) {
    localStorage.setItem('ResponseDetails', JSON.stringify(responseData))
  } else {
    localStorage.setItem('OrderDetails', JSON.stringify({
      shipping: { ...rootStore.getters['checkout/getShippingDetails'] },
      payment: { ...rootStore.getters['checkout/getPaymentDetails'] },
      personal: { ...rootStore.getters['checkout/getPersonalDetails'] },
      ceneoConsent: rootStore.getters['ui/getCeneoConsent']
    }))
  }
  localStorage.setItem('CardProducts', JSON.stringify(this.$store.state.cart.cartItems))
  localStorage.setItem('CardTotals', JSON.stringify(this.$store.state.cart.platformTotals.total_segments))
  this.$bus.$emit('test-order-placed')
}

export { storeOrderSummary }
